.back_button{
  position: fixed;
  width: auto;
  top: 100px;
  height: auto;
  display: flex;
right: 0;
  padding: 10px 40px;
  z-index: 999;
  background-color: rgb(255, 255, 255);
  a{
    cursor: pointer;

  }
  a:hover{
    transition: all .4s ease-out;
    color: orange;
    transform: translateX(-10px);
  }
}
.product {
  padding: 20px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .product_card {
   // padding: 40px;
    display: grid;
    grid-gap: 50px;
    grid-template-columns: 1fr 1fr;

    .left {
      display: grid;
      grid-template-columns: 1fr 3fr;
      gap: 10px;
     

      .images {
        display: grid;
        grid-template-rows: 1fr 1fr;
        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
          cursor: pointer;
          margin-bottom: 10px;
        }
      }
      .mainImg {
        flex: 5;

        img {
          width: 100%;
          max-height: 600px;
          object-fit: cover;
        }
      }
    } 
    @media screen and (max-width:992px) {
      .left{
        grid-template-columns: 1fr !important;
        .images{
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 0fr !important;
        }
      }
      
    }
    
    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding: 10px;

      .price {
        font-size: 30px;
        color: #2879fe;
        font-weight: 500;
      }
      p {
        font-size: 18px;
        font-weight: 300;
        text-align: justify;
      }
      .quantity {
        display: flex;
        align-items: center;
        gap: 10px;
        button {
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border: none;
          border-radius: 5px;
        }
      }
      .add {
        width: 250px;
        padding: 10px;
        background-color: orange;
        border: 2px solid orange;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        cursor: pointer;
        border-radius: 30px;
        border: none;
        font-weight: 500;
      }
      .add:hover {
        color: black;
        background-color: orange;
      }
      .noAdd{
        background-color: rgb(199, 199, 199);

      }

      .links {
        display: flex;
        gap: 20px;

        .item {
          display: flex;
          align-items: center;
          gap: 10px;
          color: #2879fe;
          font-size: 14px;
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        color: gray;
        font-size: 14px;
        margin-top: 30px;
        hr {
          width: 200px;
          border: 1px solid rgb(231, 231, 231);
        }
      }
      hr {
        border: 1px solid rgb(231, 231, 231);
      }
    }
  }
  @media screen and (max-width: 992px) {
    .product_card {
      grid-template-columns: 1fr;
     
    }

  }

  .tranding {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
