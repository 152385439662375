.list {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

}

.btn {

  padding: 8px 12px;
  margin: 0 5px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  outline: none;
}

.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

span {
  margin: 0 10px;
}