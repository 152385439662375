.products {
 min-height: 80dvh;
  padding: 30px 50px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 2rem;
  width: 100%;
    
  
 button{
  margin-top: 20px;
  padding: 5px 10px;
 font-size: 14px;
 border-radius: 5px;
 outline: none;
 border: none;
 }
  button:hover{
 background-color: orange;
  }

  .left {
     position: relative;
    
    flex: 1;

    height: 100%;
   // top: 60px;
    padding-left: 10px;

    
  .left_con{
    top: 100px;
    position: fixed;
    position: sticky !important;

    h2 {
      font-size: 400;
      margin-bottom: 10px;
    }
    .inputItem {
      margin-bottom: 5px;
      label {
        margin-left: 10px;
      }
    }

    .filterItem {
      top: 110px;
      margin-bottom: 30px;
      h2{
        font-size: 2rem;
        line-height: 1.2;
      }
    }
  }

    
  }
  .right {
    flex: 3;
    .free_banner{
      width: 100%;
      overflow: hidden;
      border-radius: 10px;
      img{
        width: 100%;
      }
    }

    .catImg {
      width: 100%;
      height: 300px;
      object-fit: cover;
      margin-bottom: 50px;
    }
  }
}
@media only screen and (max-width: 992px) {
  .products {
   // padding: 30px;
    grid-template-columns: 1fr;
    padding: 10px;
    .left {
      display: flex;
      align-items: center;
      justify-content: start;
      padding-left: 40px;
      
    }
    .navbar-nav {
      width: 100%;
      padding-top: 10px;
    }
  }
}

.heading{
    padding-bottom: 10px !important;
    font-weight: 800 !important;
    font-size: 16px !important;
  }