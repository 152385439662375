.my-navbar {
  width: 100%;
  max-width: 1400px;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;


  .navbar {
    display: none;
  }
  .m-wrap {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    grid-gap: 2rem;
    justify-content: center;
    align-items: center;
    grid-gap: 1rem;
    .m-logo {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .navbar-toggler {
      outline: none;
      border: none;
      padding: 0 !important;
      width: auto !important;
    }
  }

  .wrapper {
    background-color: rgba(255, 255, 255, 0.92);
backdrop-filter: blur(5px);
    max-width: 1400px;
    width: 100%;
    padding: 10px 30px;
    display: grid;
    grid-template-columns: 3fr .5fr 3fr;
    align-items: center;
    justify-content: space-between !important;
    grid-gap: 2rem;
  }
  @media only screen and (max-width: 992px) {
    .wrapper {
      display: none;
    }
    .navbar {
      z-index: 999;
      
      display: block !important;
      width: 100%;
      background-color: white;
    }
    .m-logo {
      width: 100%;
      img {
        width: 100px;
      }
    }

    .icons {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      gap: 15px;
      color: #777;
      cursor: pointer;
      .cartIcon {
        position: relative;

        span {
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: #2879fe;
          color: white;
          position: absolute;
          right: -10px;
          top: -10px;
          align-items: center;
          justify-content: center;
          display: flex;
        }
      }
    }
  }

  .item {
    display: flex;
    align-items: center;
    justify-content: flex-end!important;
    width: 100%;
    padding-right: 100px;
  }
  @media only screen and (max-width: 992px) {
     .item{
      padding-right: 10px !important;

     }
  }
  
  .item::hover {
    transition: all 0.2s ease-out;
    color: #fdb653;
  }
  .item:focus {
    transition: all 0.2s ease-out;
    color: #fdb653;
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    img {
      width: auto;
      height: 50px;
    }
  }
  .left {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    span{
      font-size: 13px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    span{
      font-size: 13px;
    }

    .icons {
      display: flex;
      gap: 15px;
      color: #777;
      cursor: pointer;
      .cartIcon {
        position: relative;

        span {
          position: absolute;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: #2879fe;
          color: white;
          position: absolute;
          right: -10px;
          top: -10px;
          align-items: center;
          justify-content: center;
          display: flex;
        }
      }
    }
  }

  .logo-top {
    width: 100%;
    height: auto;
    display: grid;
    align-items: center;
    justify-content:space-around;
    grid-template-columns: 1fr  1fr;
  }
}
