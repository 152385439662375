.app {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .link {
    text-decoration: none;
    color: inherit;
  }
}

select {
  appearance: none;
  border: 1px solid rgb(206, 206, 206);
  border-radius: 5px;
  outline: 0;
  background: none;
  color: inherit;
  box-shadow: none;
  padding: 5px;
  font-size: 14px;

}
select::-ms-expand {
  display: none;
}
/* Custom Select wrapper */
.select {
  position: relative;
  display: flex;
  width: min(20rem, 90vw);
  background: var(--background-select);
  border-radius: 0.25rem;
  overflow: hidden;
  ;
  // Select style
  select {
    flex: 1;
    padding: 1em;
    cursor: pointer;
  }
  // Arrow style
  &::after {
    content: "\25BC";
    position: absolute;
    right: 1rem;
    top: 1rem;
    transition: 0.25s all ease;
    pointer-events: none; // IMPORTANT to click on arrow
  }
  // Arrow hover
  &:hover::after {
    color: #f39c12;
    animation: bounce 0.5s infinite;
  }
}
@keyframes bounce {
  25% {
    transform: translatey(5px);
  }
  75% {
    transform: translatey(-5px);
  }
}
button{
  border: none;
  outline: none;
  background: none;
}